
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "kt-text",
  components: {
    CodeHighlighter,
  },
  setup() {
    const colors = ref({
      textColors: [
        "white",
        "primary",
        "secondary",
        "light",
        "success",
        "info",
        "warning",
        "danger",
        "dark",
        "muted",
        "gray-100",
        "gray-200",
        "gray-300",
        "gray-400",
        "gray-500",
        "gray-600",
        "gray-700",
        "gray-800",
        "gray-900",
      ],
      lightColors: ["primary", "success", "info", "warning", "danger", "dark"],
      inverseColors: [
        "white",
        "primary",
        "secondary",
        "light",
        "success",
        "info",
        "warning",
        "danger",
        "dark",
      ],
    });

    onMounted(() => {
      setCurrentPageTitle("Text");
    });

    return {
      colors,
    };
  },
});
